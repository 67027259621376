import * as React from "react"
import Layout from "../components/Layout/Layout";

const NotFoundPage = () => (
  <Layout
  dynamicMenuItems={[]}
  pageTitle={'404: Not Found'}
  siteHeaderLogoImageUrl={''}
  siteTitle={'404: Not Found'}
  tenantId={''}
>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
